<template>
  <div class="p-noticeList">
    <div v-if="notices.length">
      <div class="c-section c-section--fluid c-section--mb0">
        <div class="c-cards c-cards--tiled">
          <div v-for="item in notices" :key="item.id" class="c-cards__inner">
            <NoticeCard
              :href="generateUrl(item.id)"
              :src="item.image_url"
              :title="item.title"
              :subtitle="getCategory(item.category_type)"
              :date="formatDate(item.publish_start_at)"
              :noticed="!item.read"
              tiled
              @click="markAsRead(item.id)" />
          </div>
        </div>
        <!-- /.c-cards.c-cards--tiled -->

        <div
          v-if="nextPageUrl"
          class="c-section c-section--fluid c-section--white01Bg">
          <div class="p-noticeList__actions">
            <Button size="sm" style-type="tertiary" fluid @click="loadMore">
              お知らせをもっと見る
            </Button>
          </div>
        </div>
      </div>
    </div>

    <Empty
      v-else-if="!$_loading_state"
      icon-name="bell"
      text="現在お知らせはありません" />
  </div>
</template>

<script>
import { SystemDialogMessage, MessageCategoryType } from '@/constants/enums';

export default {
  components: {
    NoticeCard: () => import('@/components/mypage/NoticeCard.vue')
  },

  data: () => ({
    notices: [],
    nextPageUrl: null
  }),

  created() {
    this.fetchList();
  },

  methods: {
    async fetchList() {
      try {
        this.$_loading_start();

        const { data } = await this.$repositories('notice').getNotices(
          this.$utilities.getUrlSearch(this.nextPageUrl)
        );
        this.notices.push(...data.data);
        this.nextPageUrl = data.next_page_url;
      } catch {
        window.location.href = this.$systemDialog(
          SystemDialogMessage.ERROR_COMMON
        );
      } finally {
        this.$_loading_stop();
      }
    },

    async loadMore() {
      if (this.$_loading_state) return;
      await this.fetchList();
    },

    /**
     * お知らせを既読にする
     *
     * NOTE: APIでのデータ更新ではなく、あくまでUI要件を満たすための対応
     */
    markAsRead(noticeId) {
      const notice = this.notices.find((notice) => notice.id === noticeId);
      if (!notice) return;
      notice.read = true;
    },

    generateUrl(noticeId) {
      return this.$customUrlScheme.page(
        'standalone',
        `url=/notice/detail/${noticeId}`
      );
    },

    formatDate(datetime) {
      return this.$utilities.localizeDate(datetime);
    },

    getCategory(categoryType) {
      return MessageCategoryType.getTypeName(categoryType);
    }
  }
};
</script>
