var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "p-noticeList" },
    [
      _vm.notices.length
        ? _c("div", [
            _c(
              "div",
              { staticClass: "c-section c-section--fluid c-section--mb0" },
              [
                _c(
                  "div",
                  { staticClass: "c-cards c-cards--tiled" },
                  _vm._l(_vm.notices, function (item) {
                    return _c(
                      "div",
                      { key: item.id, staticClass: "c-cards__inner" },
                      [
                        _c("NoticeCard", {
                          attrs: {
                            href: _vm.generateUrl(item.id),
                            src: item.image_url,
                            title: item.title,
                            subtitle: _vm.getCategory(item.category_type),
                            date: _vm.formatDate(item.publish_start_at),
                            noticed: !item.read,
                            tiled: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.markAsRead(item.id)
                            },
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  0
                ),
                _vm.nextPageUrl
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "c-section c-section--fluid c-section--white01Bg",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "p-noticeList__actions" },
                          [
                            _c(
                              "Button",
                              {
                                attrs: {
                                  size: "sm",
                                  "style-type": "tertiary",
                                  fluid: "",
                                },
                                on: { click: _vm.loadMore },
                              },
                              [_vm._v(" お知らせをもっと見る ")]
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            ),
          ])
        : !_vm.$_loading_state
        ? _c("Empty", {
            attrs: { "icon-name": "bell", text: "現在お知らせはありません" },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }